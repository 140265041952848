import { isEtudePartagee, isSansGarantie } from 'containers/communs/utils';
import { useEffect, useReducer, useState } from 'react';
import {
  GetUploadUrlApiRequestParams,
  ListePiecesJustificative,
  ListePiecesJustificativesPropCialUploadable,
  TransfertOctroiResultat,
  UploadFileApiRequestBody,
  UploadFileApiResponse,
  UserInfos,
} from 'types';
import Space from 'components/space/Space';
import TransfertIcon from 'icons/TransfertIcon';
import FormFooter from 'components/formFooter/FormFooter';
import {
  getTransmissionPiecesInitialState,
  transmissionPiecesReducer,
} from 'reducers/transmissionPieces/transmissionPieces';
import { getUploadUrl, transfertAll, uploadFile } from 'api/transfertDocumentService';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';
import CheckInCircle from 'icons/CheckInCircle';
import ErrorIcon from 'icons/ErrorIcon';
import InformationArea from 'components/informationArea/InformationArea';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import { getAuthenticatedUserHabilitations } from 'utils/commun';
import { useNavigate } from 'react-router';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import TransmissionPJForAG from './ag';
import TransmissionPJForSG from './sg';
import * as messages from './messages';
import EmissionPack from './sg/emissionPack/EmissionPack';

const TransmissionPJ: React.FC = () => {
  const [chemisesUploadables, setChemisesUploadables] = useState<
    ListePiecesJustificativesPropCialUploadable[]
  >([]);

  const [chemisesNonUploadables, setChemisesNonUploadables] = useState<
    ListePiecesJustificative[]
  >([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [uploadApiErrorMessage, setUploadApiErrorMessage] = useState<string>('');
  const [transmissionPiecesState, transmissionPiecesDispatch] = useReducer(
    transmissionPiecesReducer,
    getTransmissionPiecesInitialState('transmissionPieces'),
  );
  const [displayEmissionPack, setDisplayEmissionPack] = useState<boolean>();
  const [listePJNonTransmis, setListPJNonTransmis] = useState<string[]>([]);

  const [sendingFiles, setSendingFiles] = useState<boolean>(false);

  useEffect(() => {
    if (transmissionPiecesState.isDataOk && !filesAlreadyUploaded) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [transmissionPiecesState, filesAlreadyUploaded]);

  useEffect(() => {
    const finalisationProjetFromSession = sessionStorage.getItem('finalisationProjet');
    if (finalisationProjetFromSession) {
      const finalisationProjetObject = JSON.parse(
        finalisationProjetFromSession,
      ) as FinalisationProjetState;

      setDisplayEmissionPack(finalisationProjetObject.packContractuel);
    }

    const getChemises = () => {
      try {
        const result = sessionStorage.getItem('transmissionOctroiInformation');
        if (result) {
          const transmissionInfo = JSON.parse(result) as TransfertOctroiResultat;
          setChemisesUploadables(
            transmissionInfo.listePiecesJustificativesPropCialUploadable,
          );
          setChemisesNonUploadables(
            transmissionInfo.listePiecesJustificativesPropCialNonUploadables,
          );
        }
      } catch (error) {
        alert(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
        console.error(error);
      }
    };
    getChemises();
  }, []);

  useEffect(() => {
    sessionStorage.setItem('listePJNonTransmis', JSON.stringify(listePJNonTransmis));
  }, [listePJNonTransmis]);

  const getSendFilePromise = (
    currentChemise: ChemiseData,
    url: string,
  ): Promise<UploadFileApiResponse>[] => {
    const promises: Promise<UploadFileApiResponse>[] = [];
    currentChemise.files.forEach(item => {
      const currentPromise = new Promise<UploadFileApiResponse>((resolve, reject) => {
        const body: UploadFileApiRequestBody = {
          fileName: item.content.name,
          chemiseId: currentChemise.id,
          fileContent: item.content,
        };
        uploadFile(url, body)
          .then(resp => resolve(resp))
          .catch(err => {
            console.log(`erreur sur le fichier ${body.fileName}`, err);
            setListPJNonTransmis(previsousState => [...previsousState, body.fileName]);
            resolve(err);
          });
      });
      promises.push(currentPromise);
    });
    return promises;
  };

  const navigate = useNavigate();

  const sendFiles = async () => {
    setSendingFiles(true);
    setUploadApiErrorMessage('');
    try {
      const authenticatedUser = JSON.parse(
        sessionStorage.getItem('authenticatedUser') || '',
      ) as UserInfos;
      const { numContremarque } = authenticatedUser;
      const numeroProjet = sessionStorage.getItem('numeroProjet');
      if (!numeroProjet) {
        throw Error('Numero de projet non défini.');
      }
      const params: GetUploadUrlApiRequestParams = {
        IdProjet: parseInt(numeroProjet, 10),
        AdheNum: `${numContremarque}` || '',
        ContextApp: 'APTD',
      };
      const response = await getUploadUrl(params);
      if (response.success) {
        sessionStorage.removeItem('listePJNonTransmis');
        setListPJNonTransmis([]);
        const uploadApiUrl = response.result;
        let promises: Promise<UploadFileApiResponse | string>[] = [];
        transmissionPiecesState.chemises.forEach(item => {
          promises = [...promises, ...getSendFilePromise(item, uploadApiUrl)];
        });
        if (promises.length > 0) {
          const allPromise = await Promise.all(promises);
          if (!allPromise.some(promise => promise === '')) {
            if (displayEmissionPack)
              throw Error(
                "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
              );
            else sessionStorage.setItem('documentsHaveBeenSent', 'allFail');
          }
          // l'url de l'upload est le même que celui du getTransfertAll (domaine et paramétre identiques).
          // Il faut juste changer le path single-file en getTransfertAll.
          // Disclaimer: ne pas hésiter à proposer autre chose si cette maniére de faire ne convient pas.
          const transfertApiUrl = uploadApiUrl.replace('single-file', 'getTransfertAll');
          await transfertAll(transfertApiUrl);
          sessionStorage.setItem('documentsHaveBeenSent', 'yes');
          setFilesAlreadyUploaded(true);
          if (!displayEmissionPack) navigate('/feedback');
          else {
            setShowSuccessMessage(true);
            // on affiche le message de succés de l'upload pendant 5 secondes
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 5000);
          }
        }
      } else {
        console.error(response.errors);
        setUploadApiErrorMessage(`${response.errors[0].MessageLib}`);
        setIsBtnDisabled(false);
      }
    } catch (error) {
      console.error(error);
      setUploadApiErrorMessage(
        `${
          error ||
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur."
        }`,
      );
      setIsBtnDisabled(false);
    } finally {
      setSendingFiles(false);
    }
  };

  const renderAGOrSG = () => {
    const isSG = isSansGarantie();
    /* if (isSG === undefined) {
      return null;
    } */
    if (isSG) {
      return (
        <TransmissionPJForSG
          chemisesUploadables={chemisesUploadables}
          chemisesNonUploadables={chemisesNonUploadables}
          state={transmissionPiecesState}
          dispatch={transmissionPiecesDispatch}
          disableUpload={filesAlreadyUploaded}
          allUploadAreError={uploadApiErrorMessage}
        />
      );
    }
    return (
      <TransmissionPJForAG
        chemisesUploadables={chemisesUploadables}
        chemisesNonUploadables={chemisesNonUploadables}
        state={transmissionPiecesState}
        dispatch={transmissionPiecesDispatch}
        disableUpload={filesAlreadyUploaded}
        allUploadAreError={uploadApiErrorMessage}
      />
    );
  };

  const renderErrorMessage = () => {
    if (uploadApiErrorMessage) {
      return (
        <>
          <Space marginTop="4rem" />
          <InformationArea backgroundColor="#FEF2F4" icon={<ErrorIcon />} width="100%">
            <span> {uploadApiErrorMessage}</span>
          </InformationArea>
        </>
      );
    }
    return null;
  };

  const renderSuccessUploadMessage = () => {
    return (
      <Modal
        backgroundColor="transparent"
        isVisible={showSuccessMessage}
        height="6.4rem"
        width="70rem">
        <div
          style={{
            border: '0.1rem solid #008859',
            borderRadius: '0.4rem',
            backgroundColor: '#F2F9F1',
            textAlign: 'center',
            display: 'flex',
            height: '6.4rem',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}>
          <CheckInCircle color="green" />
          <span
            style={{
              color: '#008859',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              margin: '0',
            }}>
            {messages.UPLOAD_SUCCESS_MSG}
          </span>
        </div>
      </Modal>
    );
  };

  const { dmatInd, habiPackInd } = getAuthenticatedUserHabilitations();

  const renderContractDocuments = () => {
    return (dmatInd || habiPackInd) && isEtudePartagee() ? (
      <EmissionPack isBtnDisabled={isBtnDisabled} />
    ) : null;
  };

  return (
    <>
      {withSummaryCard(
        'TransmissionDocuments',
        <div style={{ width: '63.9rem' }}>
          <Space marginBottom="10rem" />
          {renderAGOrSG()}
          {sendingFiles ? <Loader animationDuration={0.9} /> : null}
          {renderSuccessUploadMessage()}
          {renderErrorMessage()}
        </div>,
      )}

      {displayEmissionPack ? (
        <FormFooter
          hideSaveBtn
          isNextButtonDisabled={isBtnDisabled}
          showPreviousButton={false}
          onNextButtonClick={() => {
            sendFiles();
          }}
          nextButtonLabel="Transmettre les pièces"
          nextButtonIcon={<TransfertIcon />}
        />
      ) : (
        <FormFooter
          saveBtn="btn"
          onSaveButtonClick={() => {
            navigate('/feedback');
          }}
          isNextButtonDisabled={isBtnDisabled}
          showPreviousButton={false}
          onNextButtonClick={() => {
            setIsBtnDisabled(true);
            sendFiles();
          }}
          nextButtonLabel="Transmettre les pièces"
          nextButtonIcon={<TransfertIcon />}
        />
      )}
      {displayEmissionPack && renderContractDocuments()}
    </>
  );
};

export default TransmissionPJ;
