import { v4 as uuidv4 } from 'uuid';
import {
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import {
  FinalisationProjetState,
  borrowerAdditionalInfo,
} from 'reducers/finalisationProjet/types';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationState,
  Notary,
} from 'reducers/guaranteeAdditionalInformation/type';
import {
  LoanAdditionalData,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { IbanState } from 'reducers/iban/type';
import { BankDebtType, CodeLibelle, FullAddress } from 'types';
import {
  adresseDtos,
  biensImmobilier,
  clientFileResult,
  clientFilesResult,
  coordonneesBancaires,
  credit,
  emprunteur,
} from 'types/clientFileDtos';
import { CREDIT_TYPE_LIST } from 'utils/constante';
import { formatDateFr } from 'utils/DateUtils';
import { InterlocutorState } from 'reducers/interlocutor/type';
import { zipcodeNumbertoString } from 'utils/commun';
import { getZipCodeBycityAndDepartement } from 'api/referentialService';
import { checkIfNotaireDatasAreOk } from 'reducers/guaranteeAdditionalInformation/guaranteeAdditionalInformation';

const mappingFullAdresse = (
  adresse: adresseDtos,
  typeVoie: CodeLibelle[],
): FullAddress => {
  const listBis = ['B', 'T', 'Q', 'C', 'BI', 'BIS', 'TE', 'TER'];
  const fullAddress: FullAddress = {
    streetNumber: adresse?.numero,
    streetType: typeVoie.find(voie => {
      return voie.code === adresse?.typeVoie;
    }),
    street: adresse?.nomVoie,
    additionalInformation: adresse.complement || '',
    POBoxOrHamlet: adresse.boitePostalLieuDit,
    zipCode: zipcodeNumbertoString(adresse.codePostal),
    city: adresse.ville,
  };

  if (!adresse.adresse) return fullAddress;

  const splitAddress = adresse.adresse.split(' ');

  if (listBis.includes(splitAddress[1])) {
    fullAddress.streetNumber = `${splitAddress[0]}  ${splitAddress[1]}`;
    fullAddress.streetType = { code: splitAddress[2], libelle: splitAddress[2] };
    fullAddress.street = splitAddress.slice(3).join(' ');
  }
  return fullAddress;
};

const borroweradditionalInfo = (
  borrower: emprunteur | null,
  identityTypeList: CodeLibelle[],
  typeVoie: CodeLibelle[],
) => {
  const borrowerAdditionalData: borrowerAdditionalInfo = {
    address: {},
    activeProfession: false,
  };
  if (borrower && borrower !== null) {
    if (borrower?.pieceIdentite !== null) {
      borrowerAdditionalData.identityType = identityTypeList.find(
        item => item.code === borrower?.pieceIdentite?.typeIdentite,
      );
      borrowerAdditionalData.identityNumber = borrower.pieceIdentite.numeroIdentite
        .trim()
        .slice(0, 12);
    }
    borrowerAdditionalData.email = borrower.coordonnees?.email;
    borrowerAdditionalData.cellPhoneNumber = borrower.coordonnees?.telephonePortable
      ?.toString()
      .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    borrowerAdditionalData.homePhoneNumber = borrower.coordonnees?.telephoneDomicile
      ?.toString()
      .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    if (borrower.situationProfessionnelle?.employeur) {
      borrowerAdditionalData.activeProfession = true;
      borrowerAdditionalData.employerName = borrower.situationProfessionnelle?.employeur;
    }
    if (borrower.coordonnees?.adresse) {
      borrowerAdditionalData.address = mappingFullAdresse(
        borrower.coordonnees?.adresse,
        typeVoie,
      );
      // {
      //   streetNumber: borrower.coordonnees?.adresse?.numero,
      //   streetType: typeVoie.find(voie => {
      //     return voie.code === borrower.coordonnees?.adresse?.typeVoie;
      //   }),
      //   street: borrower.coordonnees?.adresse?.nomVoie,
      //   additionalInformation: borrower.coordonnees.adresse?.complement || '',
      //   POBoxOrHamlet: borrower.coordonnees.adresse?.boitePostalLieuDit,
      //   zipCode: zipcodeNumbertoString(borrower.coordonnees.adresse?.codePostal),
      //   city: borrower.coordonnees.adresse?.ville,
      // };
    }
  }
  return borrowerAdditionalData;
};

export const mappingFinalisationProjet = (
  clientFile: clientFileResult,
  identityTypeList: CodeLibelle[],
  typeVoie: CodeLibelle[],
): FinalisationProjetState => {
  const objectFromSession = sessionStorage.getItem('projetDetail');
  let projetDetailFromSession;
  if (objectFromSession) {
    projetDetailFromSession = JSON.parse(objectFromSession) as clientFilesResult;
  }

  const FinalisationProjet: FinalisationProjetState = {
    packContractuel: projetDetailFromSession?.NumeroContrat !== '0',
    ...(clientFile.demandeClientEsignatureIndicateur
      ? {
          editionType:
            clientFile.demandeClientEsignatureIndicateur === 'N'
              ? 'papier'
              : 'e-signature',
        }
      : {}),
    borrower: borroweradditionalInfo(clientFile.emprunteur, identityTypeList, typeVoie),
    coBorrower: borroweradditionalInfo(
      clientFile.coEmprunteur,
      identityTypeList,
      typeVoie,
    ),
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
    invalidInputs: [],
  };

  return FinalisationProjet;
};

export const mappingGuaranteeAdditionalInformation = async (
  clientFile: clientFileResult,
  typeEvaluation: CodeLibelle[],
  typeVoie: CodeLibelle[],
): Promise<GuaranteeAdditionalInformationState> => {
  const guaranteeAdditionalInformation: GuaranteeAdditionalInformationState = {
    guarantees: [],
    notary: { isDataOK: false },
  };
  const notaire: Notary = { isDataOK: false };

  if (clientFile.garantieHypothecaire.notaire) {
    const cityByZipCode = await getZipCodeBycityAndDepartement(
      clientFile.garantieHypothecaire.notaire.ville,
      clientFile.garantieHypothecaire.notaire.departement,
    );
    const goodCity = cityByZipCode.find(
      city =>
        city.libelle.toUpperCase() ===
        clientFile.garantieHypothecaire.notaire?.ville.trim().toUpperCase(),
    );
    notaire.name = clientFile.garantieHypothecaire.notaire.nom;
    notaire.addressZipCode = goodCity?.code;
    notaire.addressCity = goodCity;
    notaire.departmentCode = clientFile.garantieHypothecaire.notaire.departement;
  }

  clientFile.garantieHypothecaire.biensImmobiliers
    .filter(bienImmo => bienImmo.prisEnGarantieIndicateur !== 'N')
    .forEach((garantie: biensImmobilier, index) => {
      const fullAddress: FullAddress = {
        streetNumber: garantie.adresse.numero,
        streetType: typeVoie.find(voie => {
          return voie.code === garantie.adresse.typeVoie;
        }),
        street: garantie.adresse.nomVoie,
        zipCode: zipcodeNumbertoString(garantie.adresse?.codePostal),
        city: garantie.adresse.ville,
      };

      const garantieInfoAdditionnel: GuaranteeAdditionalData = {
        code: index,
        ...(garantie.adresseIdentiqueEmpIndicateur === 'O'
          ? { useBorrowerAddress: true }
          : {}),
        address: fullAddress,
        constructionYear: garantie.anneeConstruction?.toString(),
        acquisitionYear: garantie.anneeAcquisition?.toString(),
        acquisitionValue: garantie.valeurAcquisition?.toString(),
        livingSpace: garantie.surfaceHabitable?.toString(),
        evaluationType: typeEvaluation.find(
          item => item.code === garantie.methodeEvaluationBien,
        ),
        isAdditionalInformationCompleted: false,
        modificationInprogress: false,
        isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
      };

      guaranteeAdditionalInformation.guarantees.push(garantieInfoAdditionnel);
    });

  guaranteeAdditionalInformation.notary = notaire;
  guaranteeAdditionalInformation.notary.isDataOK = checkIfNotaireDatasAreOk(
    guaranteeAdditionalInformation,
  );
  return guaranteeAdditionalInformation;
};

export const mappingDeptAdditionnalInformation = (
  clientFile: clientFileResult,
  debtTypeList: CodeLibelle[],
  bankList: CodeLibelle[],
): DebtsAdditionalInformationState => {
  const debtsAdditionalInformation: DebtsAdditionalInformationState = {
    debts: [],
  };

  clientFile.projet.dettes.forEach((dette, index) => {
    const goodDept = debtTypeList.find(item => item.code === dette.type);

    const detteResult: DebtsCompleteData = {
      code: goodDept?.code || '',
      uid: `${dette.type}-${index}`,
      libelle: goodDept?.libelle || '',
      type: '',
      amount: dette.montant,
      creditorName: dette?.identiteCreancier || '',
      isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
      ...(dette.dateExigibilite && dette.type !== 'DB'
        ? { dueDate: formatDateFr(new Date(dette.dateExigibilite)) }
        : {}),
      ...(dette.civiliteCreancier
        ? {
            civility:
              dette.civiliteCreancier === 'MR'
                ? { code: 'Mr', libelle: 'Mr' }
                : { code: 'Mme', libelle: 'Mme' },
          }
        : {}),
    };

    if (dette.decouvertsBancaires) {
      let banks: BankDebtType[] = [];
      let totalAmount = 0;

      dette.decouvertsBancaires.forEach((decouvert, indexDB) => {
        const goodBank = bankList.find(
          item => item.code === decouvert.nomEtablissementBancaire,
        );
        const bank: BankDebtType = {
          id: indexDB.toString(),
          code: goodBank?.code || '',
          libelle: goodBank?.libelle || '',
          accountNumber: decouvert.numeroCompteBancaire
            .replaceAll(' ', '')
            .trim()
            .slice(0, 11),
          accountHolder: decouvert.titulaireCompteBancaire,
          amount: decouvert.montant?.toString(),
          dueDate: decouvert.dateExigibilite
            ? formatDateFr(new Date(decouvert.dateExigibilite))
            : undefined,
        };
        banks.push(bank);
        totalAmount += decouvert.montant;
      });

      if (dette.type === 'DB' && banks.length < 1) {
        banks = [{ id: uuidv4() } as BankDebtType];
      }

      detteResult.banks = banks;
      detteResult.totalBankDebts = totalAmount;
      detteResult.doesTotalBankDebtsMatchAmount = true;
    }
    debtsAdditionalInformation.debts.push(detteResult);
  });

  return debtsAdditionalInformation;
};

const getTitulaire = (titulaire: string, hasCoBorrower: boolean) => {
  switch (titulaire) {
    case '1':
      return 'borrower';
    case '2':
      return 'coBorrower';
    case '3':
      return 'both';
    default:
      return hasCoBorrower ? 'both' : 'borrower';
  }
};

export const mappingLoanAdditionalInfomation = (
  creditList: credit[],
  usagePretsList: CodeLibelle[],
  usageBiensList: CodeLibelle[],
  typeVoie: CodeLibelle[],
  hasCoBorrower: boolean,
  contexteArrivee: string,
  hasProposition: boolean,
  StatutMaitre: string,
): LoanAdditionalInformationState => {
  const CreditTypeList = CREDIT_TYPE_LIST;
  const LoanAdditionalInformation: LoanAdditionalInformationState = {
    loans: [],
  };

  creditList
    .filter(index => index.rachatIndicateur === 'O')
    .forEach((loan, index) => {
      const creditResult: LoanAdditionalData = {
        code: index,
        loanType: (() => {
          switch (loan.sousType) {
            case 'A':
              return 'creditAffecte';
            case 'P':
              return 'personnel';
            case 'L':
              return 'other';
            default:
              return undefined;
          }
        })(),
        ...(loan.natureBien !== 'AU'
          ? { loanObject: usagePretsList.find(item => item.code === loan.natureBien) }
          : {}),
        loanUsage: usageBiensList.find(item => item.code === loan.usageBien),
        loanNumber: loan.numeroPret.replaceAll(' ', '').trim(),
        ...(loan.indemniteRemboursementAnticipe
          ? { indemnityAmount: loan.indemniteRemboursementAnticipe }
          : {}),
        nominalInterestRate: loan.taux ? loan.taux.toString() : '',
        ...(loan.delaisPreavis ? { nbMonthsForNotice: loan.delaisPreavis } : {}),
        ...(loan.datePret && formatDateFr(new Date(loan.datePret)) !== '31/12/1899'
          ? { loanStartingDate: formatDateFr(new Date(loan.datePret)) }
          : {}),
        isAdditionalInformationCompleted: false,
        ...(loan.dureeActualisee
          ? { loanDuration: loan.dureeActualisee.toString() }
          : {}),
        creditType: CreditTypeList.get(loan.nature),
        ...(StatutMaitre !== 'E'
          ? { titulaire: getTitulaire(loan.titulaire, hasCoBorrower) }
          : {}),
        ...(loan.adresse
          ? {
              adresse: mappingFullAdresse(loan.adresse, typeVoie),
              // {
              //   streetNumber: loan.adresse?.numero,
              //   streetType: typeVoie.find(voie => {
              //     return voie.code === loan?.adresse?.typeVoie;
              //   }),
              //   street: loan?.adresse?.nomVoie,
              //   additionalInformation: loan.adresse?.complement || '',
              //   POBoxOrHamlet: loan.adresse?.boitePostalLieuDit || undefined,
              //   zipCode: zipcodeNumbertoString(loan.adresse?.codePostal),
              //   city: loan.adresse?.ville,
              // },
            }
          : {}),
        isDataOk: contexteArrivee !== 'ASRC' && !hasProposition,
      };

      LoanAdditionalInformation.loans.push(creditResult);
    });
  return LoanAdditionalInformation;
};

export const mappingIban = (
  coordBanc: coordonneesBancaires,
  contexteArrivee: string,
  hasProposition: boolean,
): IbanState => {
  const ibanNoSplit = coordBanc.iban;
  const ibanInfo: IbanState = {
    ibanPart1: ibanNoSplit.slice(0, 4),
    ibanPart2: ibanNoSplit.slice(4, 8),
    ibanPart3: ibanNoSplit.slice(8, 12),
    ibanPart4: ibanNoSplit.slice(12, 16),
    ibanPart5: ibanNoSplit.slice(16, 20),
    ibanPart6: ibanNoSplit.slice(20, 24),
    ibanPart7: ibanNoSplit.slice(24),
    bic: coordBanc.bic,
    isEmpty: !coordBanc.iban,
    isDataOk: contexteArrivee !== 'ASRC' && !hasProposition,
  };

  return ibanInfo;
};

export const mappingInterlocutor = (clientFile: clientFileResult): InterlocutorState => {
  const interlocutor: InterlocutorState = {
    ...(clientFile.miob ? { isMIOB: true, miobName: clientFile.miob } : {}),
    ...(clientFile.monteur ? { monteurName: clientFile.monteur } : {}),
    ...(clientFile.vendeur
      ? { interlocutorName: { code: '0', libelle: clientFile.vendeur } }
      : {}),
    ...(clientFile.analyste ? { analysteName: clientFile.analyste } : {}),
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
  };
  return interlocutor;
};
