import styled from 'styled-components';

export const StyledNoActionFilesCard = styled.div<{ color: string }>`
  height: 12rem;
  width: 35.1rem;
  border-radius: 0.4rem;
  background-color: ${({ color }) => `${color}`};
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
`;

export const StyledVerticalBar = styled.div<{ color: string }>`
  height: 12rem;
  width: 0.7rem;
  border-radius: 3.5px 0 0 3.5px;
  background-color: ${({ color }) => `${color}`};
`;

export const StyledNoActionFilesListContainer = styled.div<{ last?: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 59.5rem;
  width: 800px;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
  margin-top: 1.1rem;
  left: ${({ last }) => (last ? '109.5rem' : '')};
`;

export const StyledHorizontalBar = styled.div<{ color: string }>`
  height: 0.8rem;
  width: 80rem;
  position: absolute;
  border-radius: 4px 4px 0 0;
  background-color: ${({ color }) => `${color}`};
`;

export const StyledTriangle = styled.div<{ color: string; last?: boolean }>`
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
  border-right: 9px solid transparent;
  border-bottom: ${({ color }) => `9px solid ${color}`};
  border-left: 9px solid transparent;
  left: ${({ last }) => (last ? '44rem' : '2rem')};
  bottom: 0.9rem;
`;

export const StyledSeeOtherFilesTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
  white-space: nowrap;
`;
