import FormFooter from 'components/formFooter/FormFooter';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import SimulationIcon from 'icons/SimulationIcon';
import { useEffect, useReducer, useState } from 'react';
import { updateSessionStorage } from 'utils/storage';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import { useNavigate } from 'react-router';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import Loader from 'components/Loader';
import {
  Assurance,
  BaremesTarification,
  CodeLibelle,
  DureesPalier1Autorisees,
  InsuranceResult,
  LoanInformation,
  MinMax,
} from 'types';
import getGroupeInsuranceInformation from 'api/insuranceService';
import {
  conditionFinanciereReducer,
  getConditionFinanciereInitialState,
} from 'reducers/conditionsFinancieres/conditionsFinancieres';
import { GetSimulationResultApiResponse } from 'api/simulationService';
import getFaisabilite, { updateFaisabilite } from 'api/subscription';
import WarningIcon from 'icons/WarningIcon';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import {
  getProjectInformation,
  handleSaveAndExit,
  scrollToTopAndShowError,
} from 'utils/commun';
import { Insurance } from 'reducers/conditionsFinancieres/types';
import TextArea from 'components/textArea/TextArea';
import ErrorIcon from 'icons/ErrorIcon';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import Bareme from './Bareme';
import CoBorrowerInsurance from './insurance/CoBorrowerInsurance';
import BorrowerInsurance from './insurance/BorrowerInsurance';
import LoanDuration from './LoanDuration';
import LoanOption from './LoanOption';
import * as messages from './messages';
import IntermediationFees from './IntermediationFees';
import FranchiseDuration from './FranchiseDuration';
import { hasCoBorrower, isSansGarantie } from '../communs/utils';
import { StyledInfo, StyledInfoSimulation, StyledContentSimulation } from './style';

interface ConditionsFinancieresFormProps {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}

const ConditionsFinancieresForm: React.FC<ConditionsFinancieresFormProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  const [conditionsFinancieresState, conditionsFinancieresDispatch] = useReducer(
    conditionFinanciereReducer,
    getConditionFinanciereInitialState('conditionsFinancieres'),
  );
  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );

  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const [loanDurationMinMax, setLoanDurationMinMax] = useState<MinMax | undefined>();
  const [palierDurationMinMax, setPalierDurationMinMax] = useState<
    DureesPalier1Autorisees[] | undefined
  >();
  const [franchiseDuration, setFranchiseDuration] = useState<string>();

  const simulationResult = JSON.parse(
    sessionStorage.getItem('simulationResult') || '{}',
  ) as GetSimulationResultApiResponse;

  const [loanInfo, setLoanInfo] = useState<LoanInformation>();
  const [baremes, setBaremes] = useState<BaremesTarification[]>([]);
  const [firstPalierMax, setFirstPalierMax] = useState<number | number>();
  const [defaultBareme, setDefaultBareme] = useState<BaremesTarification | undefined>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [insuranceResponse, setInsuranceResponse] = useState<InsuranceResult>();
  const [maxIntermediationFees, setMaxIntermediationFees] = useState<number>();
  const [maxIntermediationPercentage, setMaxIntermediationPercentage] =
    useState<number>();

  const [borrowerSelectedInsurance, setBorrowerSelectedInsurance] =
    useState<CodeLibelle>();
  const [coBorrowerSelectedInsurance, setCoBorrowerSelectedInsurance] =
    useState<CodeLibelle>();

  const setInsuranceValues = (person: 'borrower' | 'coBorrower', resultat: Assurance) => {
    conditionsFinancieresDispatch({
      type: 'setDeathInsuranceChecked',
      payload: { person, value: resultat.garantieDeces === 'O' },
    });
    conditionsFinancieresDispatch({
      type: 'setDeathInsuranceRate',
      payload: { person, value: resultat.quotiteDeces },
    });

    conditionsFinancieresDispatch({
      type: 'setPtiaInsuranceChecked',
      payload: { person, value: resultat.garantiePTIA === 'O' },
    });

    conditionsFinancieresDispatch({
      type: 'setIttIptInsuranceChecked',
      payload: { person, value: resultat.garantieITT === 'O' },
    });

    conditionsFinancieresDispatch({
      type: 'setJobLossInsuranceChecked',
      payload: { person, value: resultat.garantiePerteEmploi === 'O' },
    });
  };

  const clearReccomendedInsuranceData = () => {
    conditionsFinancieresDispatch({
      type: 'setInsuranceType',
      payload: { person: 'borrower', value: {} as CodeLibelle },
    });

    conditionsFinancieresDispatch({
      type: 'setInsuranceType',
      payload: { person: 'coBorrower', value: {} as CodeLibelle },
    });

    setInsuranceResponse(undefined);
    setBorrowerSelectedInsurance(undefined);
    setCoBorrowerSelectedInsurance(undefined);
  };

  const insuranceShouldBeSet = (
    insurance: Insurance,
    selectedInsuranceCode: string | undefined,
  ): boolean => {
    return (
      selectedInsuranceCode === 'GC' ||
      (selectedInsuranceCode === 'GP' &&
        (insurance.deathInsuranceRate === undefined ||
          insurance.deathInsuranceRate === 0))
    );
  };

  const getmtAFinFromSession = (): number | undefined => {
    const result = sessionStorage.getItem('loanInformationsByIntermediationFee');
    if (result) {
      const loanInfosFromSession = JSON.parse(result) as LoanInformation;
      return Number(loanInfosFromSession.montantTotalPret);
    }
    return undefined;
  };

  const getGroupInsuranceInformation = () => {
    const isSG = isSansGarantie();
    if (isSG === undefined) {
      return;
    }

    const result = insuranceResponse;

    if (!result) {
      setIsLoading(true);

      getGroupeInsuranceInformation(
        Number(loanInfo?.identifiantEmprunteur || sessionStorage.getItem('borrowerId')),
        Number(
          loanInfo?.identifiantCoEmprunteur || sessionStorage.getItem('coBorrowerId'),
        ),
        Number(loanInfo?.montantTotalPret || getmtAFinFromSession()),
        conditionsFinancieresState.loanDuration || 0,
        isSG,
      )
        .then(response => {
          if (response.Success) {
            setInsuranceResponse(response.Resultat);

            if (
              insuranceShouldBeSet(
                conditionsFinancieresState.borrowerInsurance,
                borrowerSelectedInsurance?.code,
              )
            )
              setInsuranceValues(
                'borrower',
                response.Resultat.borrower.assuranceEmprunteurPreconisee,
              );
            if (
              hasCoBorrower() &&
              insuranceShouldBeSet(
                conditionsFinancieresState.coBorrowerInsurance,
                coBorrowerSelectedInsurance?.code,
              )
            ) {
              setInsuranceValues(
                'coBorrower',
                response.Resultat.coBorrower.assuranceEmprunteurPreconisee,
              );
            }
          }
          setIsLoading(false);
        })
        .catch(error => {
          alert(
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
          );
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (
        insuranceShouldBeSet(
          conditionsFinancieresState.borrowerInsurance,
          borrowerSelectedInsurance?.code,
        )
      )
        setInsuranceValues('borrower', result.borrower.assuranceEmprunteurPreconisee);
      if (
        hasCoBorrower() &&
        insuranceShouldBeSet(
          conditionsFinancieresState.coBorrowerInsurance,
          coBorrowerSelectedInsurance?.code,
        )
      ) {
        setInsuranceValues('coBorrower', result.coBorrower.assuranceEmprunteurPreconisee);
      }
    }
  };

  useEffect(() => {
    const searchPalier = (total: number, item: DureesPalier1Autorisees) => {
      const min = parseInt(item.pretTotDurMin, 10);
      const max = parseInt(item.pretTotDurMax, 10);
      if (!Number.isNaN(max) && total >= min && total <= max) {
        return true;
      }
      return false;
    };
    if (
      conditionsFinancieresState.loanDuration !== undefined &&
      conditionsFinancieresState.franchiseDuration !== undefined
    ) {
      const total =
        conditionsFinancieresState.loanDuration +
        conditionsFinancieresState.franchiseDuration;
      const result = palierDurationMinMax?.find(item => searchPalier(total, item));
      if (result) {
        setFirstPalierMax(
          parseInt(result.palDur1Max, 10) - conditionsFinancieresState.franchiseDuration,
        );
      }
    }
  }, [
    conditionsFinancieresState.loanDuration,
    conditionsFinancieresState.franchiseDuration,
  ]);

  const saveInSessionStorage = (): void => {
    updateSessionStorage('conditionsFinancieres', conditionsFinancieresState);
  };

  const onSaveButtonClick = (): void => {
    setIsGeneralConfirmationModalVisible(true);
  };

  const closeModal = (): void => {
    setIsGeneralConfirmationModalVisible(false);
  };

  const sortBaremeByMinDureeDescending = (data: BaremesTarification[]) => {
    if (data.length > 1) {
      data.sort((a, b) => {
        if (a.dureeMinPret > b.dureeMinPret) {
          return 1;
        }
        return -1;
      });
    }
  };

  const getLoanData = (data: LoanInformation) => {
    clearReccomendedInsuranceData();
    setLoanInfo(data);
    setLoanDurationMinMax({
      min: data.produitCommercial[0].dureeMin,
      max: data.produitCommercial[0].dureeMax,
    });
    const paliers: DureesPalier1Autorisees[] = [...data.dureesPalier1Autorisees];
    if (paliers.length > 1) {
      paliers.sort((a, b) => {
        if (parseInt(a.pretTotDurMin, 10) > parseInt(b.pretTotDurMax, 10)) {
          return 1;
        }
        return -1;
      });
      setPalierDurationMinMax(paliers);
    }
    setFranchiseDuration(data.produitCommercial[0].dureeDiffereVente);
    sortBaremeByMinDureeDescending(data.baremesTarification);
    setBaremes(data.baremesTarification);
  };

  const searchDefaultBareme = (item: BaremesTarification, total: number) => {
    const min = item.dureeMinPret;
    const max = item.dureeMaxPret;
    if (total >= min && total <= max) {
      return true;
    }
    return false;
  };

  const getCurrentBareme = () => {
    if (!baremes || baremes.length === 0) {
      return;
    }
    if (
      conditionsFinancieresState.franchiseDuration !== undefined &&
      conditionsFinancieresState.loanDuration !== undefined
    ) {
      const total = conditionsFinancieresState.loanDuration;
      const result = baremes.find(item => searchDefaultBareme(item, total));
      if (result) {
        setDefaultBareme(result);
        sessionStorage.setItem('baremeEnVigeur', JSON.stringify(result));
      } else {
        setDefaultBareme(result);
        sessionStorage.removeItem('baremeEnVigeur');
      }
    }
  };

  useEffect(() => {
    getCurrentBareme();
  }, [
    baremes,
    conditionsFinancieresState.franchiseDuration,
    conditionsFinancieresState.loanDuration,
  ]);

  const getSimulation = async () => {
    setMaxIntermediationFees(undefined);
    setMaxIntermediationPercentage(undefined);

    try {
      setIsLoading(true);
      const projectInformation = getProjectInformation();

      const response = projectInformation.numeroProjet
        ? await updateFaisabilite(projectInformation)
        : await getFaisabilite();
      setIsLoading(false);
      if (response && response.data.Resultat && response.data.Resultat.statut === 'OK') {
        updateSessionStorage('simulationResult', response.data);
        updateSessionStorage('numeroProjet', response.data.Resultat.numeroProjet);
        updateSessionStorage('borrowerId', response.data.Resultat.identifiantEmprunteur);
        if (response.data.Resultat.identifiantCoEmprunteur) {
          updateSessionStorage(
            'coBorrowerId',
            response.data.Resultat.identifiantCoEmprunteur,
          );
        }
        onNextButtonClick();
      } else if (response) {
        if (response.data.Resultat) {
          setMaxIntermediationFees(
            response.data.Resultat.Proposition?.montantFraisIntermediationMax,
          );
          setMaxIntermediationPercentage(
            response.data.Resultat.Proposition?.tauxFraisIntermediationMax,
          );
        }
        if (response.data.ListeMessages && response.data.ListeMessages.length > 0) {
          setErrorMessages(
            response.data.ListeMessages.filter(message => {
              return message.MessageType === 'E' || 'T';
            }),
          );
        }
      }
    } catch (error) {
      console.error(error);
      setErrorMessages([
        {
          MessageType: 'T',
          MessageId: 0,
          MessageLib:
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        },
      ] as ErrorMessage[]);
      setIsLoading(false);
    }
  };

  return (
    <>
      {withSummaryCard(
        'Simulation',
        <>
          {hasError ? (
            <>
              <Space marginTop="2.4rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
              <Space marginBottom="2.4rem" />
            </>
          ) : (
            <Space marginBottom="9.6rem" />
          )}
          <div style={{ width: '46.4rem' }}>
            <StepTitles title={messages.TITLE} subTitle={messages.SUB_TITLE} />
            <Space marginTop="2.4rem" />
            <IntermediationFees
              state={conditionsFinancieresState}
              dispatch={conditionsFinancieresDispatch}
              maxIntermediationFees={maxIntermediationFees}
              maxIntermediationPercentage={maxIntermediationPercentage}
              isDataLoading={val => {
                setIsLoading(val);
              }}
              handleLoanInformationByIntermediationFee={getLoanData}
              checkInputs={checkInputs}
            />
            <>{isLoading && <Loader animationDuration={0.9} />}</>
            <Space marginTop="5.6rem" />
            <LoanDuration
              state={conditionsFinancieresState}
              dispatch={conditionsFinancieresDispatch}
              loanDurationInterval={loanDurationMinMax}
              clearReccomendedInsuranceData={clearReccomendedInsuranceData}
              checkInputs={checkInputs}
            />
            <Space marginTop="2.4rem" />
            <FranchiseDuration
              state={conditionsFinancieresState}
              dispatch={conditionsFinancieresDispatch}
              franchiseMaxDuration={franchiseDuration}
              checkInputs={checkInputs}
            />
            {isSansGarantie() && (
              <>
                <Space marginTop="2.4rem" />
                <LoanOption
                  state={conditionsFinancieresState}
                  dispatch={conditionsFinancieresDispatch}
                  palierMax={firstPalierMax}
                />
              </>
            )}
            <Space marginTop="5.6rem" />
            <Bareme
              state={conditionsFinancieresState}
              dispatch={conditionsFinancieresDispatch}
              handleGetDefaulBaremeClick={() => {
                if (defaultBareme) {
                  return defaultBareme;
                }
                const valueFromSS = sessionStorage.getItem('baremeEnVigeur');
                if (valueFromSS) {
                  return JSON.parse(valueFromSS) as BaremesTarification;
                }
                return undefined;
              }}
              checkInputs={checkInputs}
            />

            <Space marginTop="5.6rem" />
            <BorrowerInsurance
              state={conditionsFinancieresState}
              dispatch={conditionsFinancieresDispatch}
              getGroupInsuranceInformation={getGroupInsuranceInformation}
              selectedInsurance={borrowerSelectedInsurance}
              setSelectedInsurance={setBorrowerSelectedInsurance}
              checkInputs={checkInputs}
              insuranceResponse={insuranceResponse}
            />

            {borrowerState.hasCoBorrower && (
              <>
                <Space marginBottom="5.6rem" />
                <CoBorrowerInsurance
                  state={conditionsFinancieresState}
                  dispatch={conditionsFinancieresDispatch}
                  getGroupInsuranceInformation={getGroupInsuranceInformation}
                  selectedInsurance={coBorrowerSelectedInsurance}
                  setSelectedInsurance={setCoBorrowerSelectedInsurance}
                  checkInputs={checkInputs}
                  insuranceResponse={insuranceResponse}
                />
              </>
            )}

            <GeneralConfirmationModal
              isVisible={isGeneralConfirmationModalVisible}
              accept={() => {
                handleSaveAndExit({
                  setErrorMessages,
                  setIsLoading,
                  setIsModalVisible: setIsGeneralConfirmationModalVisible,
                  saveInSessionStorage,
                  navigate,
                });
              }}
              close={closeModal}
            />
          </div>
        </>,
        {
          loanInformation: loanInfo,
        },
      )}

      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      {simulationResult?.Resultat?.Proposition?.nbAppelsRestants === 1 && (
        <StyledInfoSimulation>
          <ErrorIcon color="#E78A08" />
          <div>
            <StyledContentSimulation
              style={{
                height: '24px',
                color: '#292C2',
                fontWeight: 'bold',
                lineHeight: '24px',
              }}>
              {messages.SIMULATION_REMAINING_WARNING_TITLE}
            </StyledContentSimulation>
            <StyledContentSimulation>
              {messages.SIMULATION_REMAINING_WARNING_MESSAGE}
            </StyledContentSimulation>
          </div>
        </StyledInfoSimulation>
      )}

      <FormFooter
        marginTop="4.8rem"
        showPreviousButton
        onSaveButtonClick={onSaveButtonClick}
        onNextButtonClick={() => {
          if (conditionsFinancieresState.isDataOk) {
            saveInSessionStorage();
            getSimulation();
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
        nextButtonLabel="Obtenir la faisabilité"
        nextButtonIcon={<SimulationIcon color="#fff" />}
        onPreviousButtonClick={onBackButtonClick}
      />
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default ConditionsFinancieresForm;
