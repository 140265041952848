import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import checkPreviousInputValidation, {
  customReplaceAll,
  formatValues,
  getProjectInformation,
} from 'utils/commun';
import ArrowLeftRightIcon from 'icons/ArrowLeftRightIcon';
import getLoanInformationByIntermediationFee from 'api/loanService';
import { updateSessionStorage } from 'utils/storage';
import { LoanInformation } from 'types';
import { StyledTitle } from 'containers/communs/style';
import WarningIcon from 'icons/WarningIcon';
import { ValidationRules, checkPercentageValue } from 'utils/InputValidation';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import { OperationState } from 'reducers/operationClient/types';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import InformationArea from 'components/informationArea/InformationArea';
import { StyledFlexContainer, StyledInfo, StyledRectangle } from './style';
import { addInInvalidInputs, removeFromInvalidInputs } from '../communs/utils';

interface IntermediationFeesProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  maxIntermediationFees: number | undefined;
  maxIntermediationPercentage: number | undefined;
  isDataLoading: (value: boolean) => void;
  handleLoanInformationByIntermediationFee: (value: LoanInformation) => void;
  checkInputs: boolean;
}

const IntermediationFees: React.FC<IntermediationFeesProps> = ({
  state,
  dispatch,
  maxIntermediationFees,
  maxIntermediationPercentage,
  isDataLoading,
  handleLoanInformationByIntermediationFee,
  checkInputs,
}) => {
  const [interestRateValue, setInterestRateValue] = useState<string | undefined>(
    state?.intermediationFeesRate || '',
  );
  const [inputType, setInputType] = useState<string>(state.inputType || 'percentage');
  const [
    loanInformationsByIntermediationFeeState,
    setLoanInformationsByIntermediationFeeState,
  ] = useState<LoanInformation | undefined>();
  const [isIntermediationFeeInputFocused, setIsIntermediationFeeInputFocused] =
    useState<boolean>(false);
  const [isIntermediationFeeInputValid, setIsIntermediationFeeInputValid] = useState<
    boolean | undefined
  >(
    checkPreviousInputValidation(
      'conditionsFinancieres',
      state.intermediationFeesRate ? state.intermediationFeesRate.toString() : '',
    ),
  );

  const [intermediationFeeInputError, setIntermediationFeeInputError] =
    useState<string>('');
  const [intermediationFeeBlured, setIntermediationFeeBlured] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [hasTechnicalError, setTechnicalError] = useState<boolean>(false);
  const [totalNeeds, setTotalNeeds] = useState<number>(0);

  const realNumbers = new RegExp(ValidationRules.realNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const handleTechnicalError = () => {
    setTechnicalError(true);

    setErrorMessages([
      {
        MessageLib: 'Une erreur technique est survenue, contactez votre administrateur ',
      },
    ] as ErrorMessage[]);
  };

  const getLoanInformation = (intermediationFeesRate?: string) => {
    isDataLoading(true);

    const { numeroProjet, identifiantEmprunteur, identifiantCoEmprunteur } = {
      ...getProjectInformation(),
    };

    const interFeesRate = state.intermediationFeesRate || intermediationFeesRate;

    setErrorMessages([]);

    getLoanInformationByIntermediationFee(
      Number(customReplaceAll(interFeesRate || '', ',', '.')),
      state.inputType,
      numeroProjet,
      identifiantEmprunteur,
      identifiantCoEmprunteur,
    )
      .then(
        response => {
          if (response.ListeMessages[0]?.MessageType === 'T') {
            handleTechnicalError();
          } else {
            setTechnicalError(false);
            setErrorMessages(response.ListeMessages);
          }
          updateSessionStorage('loanInformationsByIntermediationFee', response.Resultat);
          sessionStorage.setItem(
            'pourcentageFraisIntermediation',
            JSON.stringify({ value: interFeesRate, type: inputType }),
          );
          setLoanInformationsByIntermediationFeeState(response.Resultat);
          if (response.Resultat?.numeroProjet)
            updateSessionStorage('numeroProjet', response.Resultat?.numeroProjet);
          if (response.Resultat?.identifiantEmprunteur)
            updateSessionStorage('borrowerId', response.Resultat?.identifiantEmprunteur);
          if (response.Resultat?.identifiantCoEmprunteur) {
            updateSessionStorage(
              'coBorrowerId',
              response.Resultat.identifiantCoEmprunteur,
            );
          }
          if (response.Resultat)
            handleLoanInformationByIntermediationFee(response.Resultat);
        },
        () => {
          handleTechnicalError();
        },
      )
      .catch(error => {
        console.log(error); // TODO: handle error
      })
      .finally(() => {
        isDataLoading(false);
      });
  };

  useEffect(() => {
    // Besoin du client = dettes à racheter + totalOtherNeedsAmount + total des prêts à racheter
    const result = sessionStorage.getItem('operationsClient');
    if (result) {
      const operationsClientData = JSON.parse(result) as OperationState;

      let needs = 0;
      if (operationsClientData?.totalOtherNeedsAmount) {
        needs += operationsClientData.totalOtherNeedsAmount;
      }
      if (operationsClientData?.totalDebtValue) {
        needs += operationsClientData.totalDebtValue;
      }
      if (operationsClientData?.buybackLoansTotal) {
        needs += operationsClientData.buybackLoansTotal;
      }
      setTotalNeeds(needs);
    }

    const pourcentageFraisIntermediationFromSection = sessionStorage.getItem(
      'pourcentageFraisIntermediation',
    );

    const loanInformationsByIntermediationFeeSection = sessionStorage.getItem(
      'loanInformationsByIntermediationFee',
    );

    if (pourcentageFraisIntermediationFromSection) {
      const fraisIntermediation = JSON.parse(pourcentageFraisIntermediationFromSection);
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: fraisIntermediation.value,
      });
      dispatch({
        type: 'setInputType',
        payload: fraisIntermediation.type === 'M' ? 'M' : 'P',
      });
      setInterestRateValue(fraisIntermediation.value);
      setInputType(fraisIntermediation.type);
      setIsIntermediationFeeInputValid(true);
      setIntermediationFeeInputError('');
      // setIntermediationFeeBlured(true);
      removeFromInvalidInputs(state, dispatch, 'intermediationFees');

      if (!loanInformationsByIntermediationFeeSection) {
        getLoanInformation(fraisIntermediation.value);
      }
    }

    if (loanInformationsByIntermediationFeeSection) {
      setLoanInformationsByIntermediationFeeState(
        JSON.parse(loanInformationsByIntermediationFeeSection),
      );
    }
  }, []);

  useEffect(() => {
    if (intermediationFeeBlured) {
      getLoanInformation();
    }
  }, [intermediationFeeBlured]);

  const handleIntermediationFeeChange = (value: string) => {
    if (!realNumbers.test(value)) {
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: undefined,
      });
      return;
    }
    if (state.inputType === 'P' && checkPercentageValue(value)) {
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: undefined,
      });
      return;
    }
    if (value.length > 5) {
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: undefined,
      });
      return;
    }

    setInterestRateValue(value);
    setIntermediationFeeBlured(false);
    if (Number.isNaN(Number(value.replaceAll(',', '.'))))
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: undefined,
      });
    else
      dispatch({
        type: 'setIntermediationFeesRate',
        payload: value,
      });
  };

  const handleIntermediationFeeBlur = () => {
    setIsIntermediationFeeInputFocused(false);
    if (!notEmpty.test(interestRateValue || '')) {
      setIsIntermediationFeeInputValid(false);
      setIntermediationFeeInputError(messagesCheckInput.REQUIRED_VALUE_NO_WRAP);
      setIntermediationFeeBlured(false);
      addInInvalidInputs(state, dispatch, 'intermediationFees');
    } else if (Number(interestRateValue) < 0) {
      setIsIntermediationFeeInputValid(false);
      setIntermediationFeeInputError(
        messagesCheckInput.INTERMEDIATION_FEE_POSITIVE_NUMBER,
      );
      setIntermediationFeeBlured(false);
      addInInvalidInputs(state, dispatch, 'intermediationFees');
    } else {
      setIsIntermediationFeeInputValid(true);
      setIntermediationFeeInputError('');
      setIntermediationFeeBlured(true);
      removeFromInvalidInputs(state, dispatch, 'intermediationFees');
    }
  };

  const changeInputType = () => {
    // reset input to default values
    setIsIntermediationFeeInputValid(undefined);
    setIntermediationFeeInputError('');
    if (inputType === 'M') {
      setInputType('P');
      dispatch({
        type: 'setInputType',
        payload: 'P',
      });
      if (loanInformationsByIntermediationFeeState?.pourcentageFraisIntermediation) {
        setInterestRateValue(
          loanInformationsByIntermediationFeeState?.pourcentageFraisIntermediation.toString(),
        );
        dispatch({
          type: 'setIntermediationFeesRate',
          payload:
            loanInformationsByIntermediationFeeState?.pourcentageFraisIntermediation.toString(),
        });
      } else setInterestRateValue('');
    } else {
      setInputType('M');
      dispatch({
        type: 'setInputType',
        payload: 'M',
      });
      if (loanInformationsByIntermediationFeeState?.fraisIntermediation) {
        setInterestRateValue(
          loanInformationsByIntermediationFeeState?.fraisIntermediation.toString(),
        );
        dispatch({
          type: 'setIntermediationFeesRate',
          payload:
            loanInformationsByIntermediationFeeState?.fraisIntermediation.toString(),
        });
      } else setInterestRateValue('');
    }
  };

  const renderRetryButton = () => {
    return (
      <>
        <span>
          {`ou `}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => getLoanInformation()}
            role="button"
            onKeyDown={() => getLoanInformation()}
            tabIndex={0}>
            <span>cliquez ici</span>
          </span>
          {` pour rejouer l'appel.`}
        </span>
      </>
    );
  };

  useEffect(() => {
    if (checkInputs) {
      handleIntermediationFeeBlur();
    }
  }, [checkInputs]);

  useEffect(() => {
    console.log('test');
    sessionStorage.setItem(
      'pourcentageFraisIntermediation',
      JSON.stringify({ value: interestRateValue, type: inputType }),
    );
  }, [inputType]);

  return (
    <>
      <StyledTitle>{`Frais d'intermédiation`}</StyledTitle>
      <Space marginTop="1.6rem" />
      <StyledFlexContainer gap="1rem" align="center">
        <CustomInput
          name="intermediation-fees-rate"
          label="Frais d'intermédiation"
          value={interestRateValue || ''}
          onChange={newValue => {
            handleIntermediationFeeChange(newValue.replaceAll(' ', ''));
            setIsIntermediationFeeInputFocused(true);
          }}
          isValid={isIntermediationFeeInputValid}
          isFocused={isIntermediationFeeInputFocused}
          onBlur={handleIntermediationFeeBlur}
          onFocus={() => {
            setIsIntermediationFeeInputFocused(true);
          }}
          after={inputType === 'M' ? '€' : '%'}
          inputWidth="16.4rem"
          placeholder="0"
          className="bareme-input"
          error={intermediationFeeInputError}>
          {' '}
          <StyledRectangle
            onClick={changeInputType}
            // style={{
            //   // marginTop: isIntermediationFeeInputValid === false ? '0.5rem' : '3rem',
            // }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <ArrowLeftRightIcon />
            </div>
            <span style={{ fontWeight: 'bold' }}>
              {`saisir en ${inputType === 'M' ? '%' : '€'} `}{' '}
            </span>
          </StyledRectangle>
        </CustomInput>
      </StyledFlexContainer>

      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '3.2rem' }}>
          <div style={{ minWidth: '2rem' }}>
            <WarningIcon />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.8rem' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                  {hasTechnicalError && renderRetryButton()}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      {maxIntermediationFees && maxIntermediationPercentage && (
        <>
          <Space marginBottom="3.2rem" />
          <InformationArea
            icon={<WarningIcon color="#2275D7" />}
            backgroundColor="blue"
            height="10rem">
            <p
              style={{
                margin: '0',
                fontSize: '1.4rem',
              }}>{`Les frais d’intermédiation maximum applicables sont estimés à ${maxIntermediationPercentage}% soit ${formatValues(
              maxIntermediationFees.toString(),
              '€',
            )}`}</p>
            <p
              style={{
                margin: '0',
                fontSize: '1.2rem',
              }}>{`Un ajustement de 0,01 à 0.03 point de pourcentage soit ${Math.floor(
              totalNeeds / 9999,
            )} € à ${
              Math.floor(totalNeeds / 9999) * 3
            } € sur ces frais pourrait être nécessaire`}</p>
          </InformationArea>
        </>
      )}
    </>
  );
};

export default IntermediationFees;
