import '@design-system/base-button';
import '@design-system/numeric-stepper';
import '@design-system/base-input';
import '@design-system/gender-input';
import '@design-system/base-select';
import '@design-system/base-checkbox';
import '@design-system/step-souscription-breadcrumb';
import '@design-system/base-collapse';
import getEnvVariables from 'api/envService';
import Login from 'containers/login';
import FindBordereau from 'pages/bordereau/FindBordereau';
import { useEffect, useState } from 'react';
import Authorize from 'pages/Authorization/Authorize';
import Callback from 'pages/Authorization/Callback';
import SilentRefresh from 'pages/Authorization/SilentRefresh';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import GlobalStyle from 'styles/globalStyles';
import { ConfigValues } from 'types';
import { ThemeProvider } from 'styled-components';
import themeGlobal from 'styles/theme';
import DonneesClient from 'pages/donneesClient/DonneesClient';
import OperationClient from 'pages/operationClient/OperationClient';
import Consultation from 'pages/consultation/Consultation';
import './App.css';
import {
  withProfile,
  withSideBar,
  wrapContentWithBreadcrumb,
  wrapContentWithoutBreadcrumb,
} from 'containers/communs/higherOrderComponentsUtils';
import ConditionsFinancieres from 'pages/conditionsfinancieres/ConditionsFinancieres';
import Result from 'pages/Result/Result';
import FinalisationProjet from 'pages/finalisationProjet/FinalisationProjet';
import AuthorizationService from 'api/AuthorizationService';
import TransmissionPieces from 'pages/transmissionPieces/TransmissionPieces';
import Search from 'pages/Search/Search';
import Dashboard from 'pages/Dashboard/Dashboard';
import Utils from 'pages/Utils/utils';
import '@design-system/pf-mediator';
import Feedback from 'pages/feedback/Feedback';
import Calculette from 'pages/calculette/Calculette';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    export interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'pf-mediator': any; // eslint-disable @typescript-eslint/no-explicit-any
    }
  }
}

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const ProtectedRoute = ({ isAllowed, redirectPath = '/' }) => {
  let actualRedirectPath = redirectPath;
  // When the IAM is down you can set this flag to true to bypass the authentication process
  let bypassFlag = false;

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_BYPASS_AUTH === 'true'
  ) {
    bypassFlag = true;
  }

  if (!bypassFlag) {
    if (sessionStorage.getItem('isUserAuthenticated') !== 'true') {
      actualRedirectPath = '/';
    }

    if (!isAllowed) {
      return <Navigate to={actualRedirectPath} replace />;
    }
  } else {
    // You can add mocked data here in case they are needed when bypassing the authentication process
    const uid = process.env.REACT_APP_MOCKED_UID || '';

    if (!sessionStorage.getItem('authenticatedUser')) {
      AuthorizationService.getAuthorization(uid).then(result => {
        if (result.data.Success) {
          const mockedUserInfos = { ...result.data.Resultat };
          mockedUserInfos.uid = uid;
          sessionStorage.setItem('authenticatedUser', JSON.stringify(mockedUserInfos));
        }
      });
    }
  }

  return <Outlet />;
};

const App: React.FunctionComponent<Record<string, never>> = () => {
  const [envVariablesLoading, setEnvVariablesLoading] = useState<boolean>(true);
  const [errorEnvVariables, setErrorEnvVariables] = useState<string>('');

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configs: ConfigValues = await getEnvVariables();
        sessionStorage.setItem('envVariables', JSON.stringify(configs));
        setErrorEnvVariables('');
      } catch (error) {
        setErrorEnvVariables(`${error}`);
      } finally {
        setEnvVariablesLoading(false);
      }
    };
    fetchConfig();
  }, []);

  const render = () => {
    if (envVariablesLoading) {
      return <p>Loading env variables...</p>;
    }
    if (errorEnvVariables) {
      return <p>{errorEnvVariables}</p>;
    }
    return (
      <>
        <GlobalStyle />
        <ThemeProvider
          theme={{
            ...themeGlobal,
            variables: {
              ...themeGlobal,
              colors: { ...themeGlobal.colors },
            },
          }}>
          <Router>
            <Wrapper>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/authorize" element={<Authorize />} />
                <Route path="/callback" element={<Callback />} />
                <Route
                  element={
                    <ProtectedRoute
                      isAllowed={sessionStorage.getItem('isUserAuthenticated')}
                    />
                  }>
                  <Route path="/silent-refresh" element={<SilentRefresh />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed />}>
                  <Route
                    path="bordereau"
                    element={wrapContentWithoutBreadcrumb(
                      <FindBordereau />,
                      'BORDEREAUX DE COMMISSIONNEMENT',
                    )}
                  />
                  <Route
                    path="donnees_client"
                    element={wrapContentWithBreadcrumb(<DonneesClient />)}
                  />
                  <Route
                    path="operation_client"
                    element={wrapContentWithBreadcrumb(<OperationClient />)}
                  />
                  <Route
                    path="conditions_financieres"
                    element={wrapContentWithBreadcrumb(<ConditionsFinancieres />)}
                  />
                  <Route
                    path="resultat"
                    element={wrapContentWithBreadcrumb(<Result />)}
                  />
                  <Route
                    path="finalisation_projet"
                    element={wrapContentWithBreadcrumb(<FinalisationProjet />)}
                  />
                  <Route
                    path="transmission_pieces"
                    element={wrapContentWithBreadcrumb(<TransmissionPieces />)}
                  />
                  <Route
                    path="feedback"
                    element={wrapContentWithBreadcrumb(<Feedback />)}
                  />
                  <Route
                    path="consultation"
                    element={withSideBar(withProfile(<Consultation />))}
                  />
                  <Route
                    path="recherche"
                    element={withSideBar(withProfile(<Search />))}
                  />
                  <Route
                    path="dashboard"
                    element={withSideBar(withProfile(<Dashboard />))}
                  />
                  <Route
                    path="calculette"
                    element={withSideBar(withProfile(<Calculette />))}
                  />
                  <Route path="utils/icons" element={withSideBar(<Utils />)} />
                </Route>
              </Routes>
            </Wrapper>
          </Router>
        </ThemeProvider>
      </>
    );
  };
  return <>{render()}</>;
};

export default App;
